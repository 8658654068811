import React, {useEffect, useState} from 'react';
import Jumbotron from '../component/Jumbotron';
import dummyGallery from '../asset/dummy/_gallery';
import img1 from '../asset/images/Rapor Pendidikan.png';
import img2 from '../asset/images/Perencanaan Berbasis Data.png';
import { useNavigate } from 'react-router-dom';
import { Clock, ArrowUpRight } from "@phosphor-icons/react";
import { formatDate, truncateHTML, truncateText } from '../utils/utils';
import Statistics from '../component/Statistics';
import { getInfo } from '../store/actions/infoAction';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';


const Home = () => {
  const dispatch = useDispatch();
  const ragamInfo = useSelector(state => state.info.data);
  const loadingRagamInfo = useSelector(state => state.info.loading);
  const dataMenu = useSelector(state => state.menu.data);
  const loadingMenu = useSelector(state => state.menu.loading);
  const dataGeneral = useSelector(state => state.general.data);
  const loadingGeneral = useSelector(state => state.general.loading);

  const [header, setHeader] = useState([])
  const [priority, setPriority] = useState([])
  const [activeTab, setActiveTab] = useState('article')
  const navigate = useNavigate()

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '100px',
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '60px',
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '40px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  };

  const infoTab = [
    {id:'article', label:'Artikel', menuId: 14}, 
    {id:'news', label:'Berita', menuId:15}, 
    {id:'story', label:'Berbagi Cerita', menuId:16}
  ]

  useEffect(() => {
    dispatch(getInfo(activeTab, 'desc', 1, ''));
  }, [dispatch, activeTab]);

  useEffect(() => {
    if (dataMenu && !loadingMenu) {
      const pr = dataMenu.find(a => a.slug === 'program-prioritas');
      if (pr) {
        const list_menu = dataMenu.filter(a => a.parent_id === pr.id);
        setPriority(list_menu);
      } else {
        console.warn('Program Prioritas not found in dataMenu');
      }
    }
  }, [dataMenu, loadingMenu]);

  useEffect(() => {
    if (ragamInfo) {
      const _headList = ragamInfo?.data?.slice(0, 5).map((item) => ({
        images: item?.featured_image?.url,
        title: item?.title,
        category: item?.category?.description,
        author: item?.author?.name,
        date: formatDate(item?.created_at),
        categoryObj: item?.category,
        slug: item?.slug
      }));
      setHeader(_headList);
    }
  }, [ragamInfo]);

  return (
    <div>
      <Helmet>
        <title>Direktorat SMP - Kementerian Pendidikan, Kebudayaan, Riset dan Teknologi</title>
        <meta name="description" content="Website resmi Direktorat Sekolah Menengah Pertama Kementerian Pendidikan dan Kebudayaan Republik Indonesia." />
        <meta property="og:title" content="Direktorat SMP - Kementerian Pendidikan, Kebudayaan, Riset dan Teknologi" />
        <meta property="og:description" content="Website resmi Direktorat Sekolah Menengah Pertama Kementerian Pendidikan dan Kebudayaan Republik Indonesia." />
        <meta property="og:image" content={dataGeneral?.logo_header?.[0]?.image?.url} />
        <meta property="og:url" content={window.location.origin} />
        <meta property="og:type" content="website" />
      </Helmet>

      <Jumbotron data={header} isHome/>

      <div className="md:pt-[105vh] pt-[450px] mb-8 md:px-12">
        <div className="px-6">
          <div className="py-1 w-fit text-xl text-gray-800 border-b-4 border-yellow-400 mb-3 font-bold">Program Prioritas</div>
        </div>
        <Slider {...settings} className='px-6'>
          {priority.map((item,key) => {
            
            var thumbnailUrl;
            if(item?.type == 'file-content') { 
              if(item?.file_content?.thumbnail_url) {
                thumbnailUrl = item?.file_content?.thumbnail_url;
              } else {
                thumbnailUrl = img1;
              }
            } else if (item?.type == 'single-content') {
              if(item?.single_content?.thumbnail_url) {
                thumbnailUrl = item?.single_content?.thumbnail_url;
              } else {
                thumbnailUrl = img2;
              }
            } else {
              thumbnailUrl = key%2 == 1 ? img1 : img2
            }

            return(
              <div key={key} className='p-3'>
                <div className='flex flex-none flex-col items-center space-y-1 rounded-xl shadow-sm'>
                  <a href={`${item?.type}/${item?.slug}`} className='rounded-xl'>
                    <img src={thumbnailUrl} alt={item.name} className='w-[320px] h-[200px] object-cover rounded-t-xl'/>
                    <div className="h-[60px] flex items-center justify-center text-center text-sm px-3 py-2 rounded-b-xl bg-gradient-to-r from-[#016BA3] to-[#00486B] text-white font-semibold uppercase w-full">
                      {item.name}
                    </div>
                  </a>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      
      <div className="mb-8 md:px-12">
        <div className="px-6 md:flex md:justify-between items-center mb-3">
          <div className="py-1 w-fit text-xl text-gray-800 border-b-4 border-yellow-400 mb-3 font-bold">Ragam Informasi</div>
          <div className="bg-[#F0F7FC] rounded-full p-2 flex gap-3">
            {infoTab.map((item, key) => {
              return(
                <div key={key} className={`rounded-full px-2 text-sm cursor-pointer ${activeTab === item?.id ? 'bg-white text-[#008DD1] font-semibold' : 'text-[#85AAD1] font-normal'}`} onClick={() => setActiveTab(item?.id)}>
                  {item.label}
                </div>
              )
            })}
          </div>
        </div>

        {!loadingRagamInfo &&
          <div className="md:grid md:grid-cols-2 gap-10 px-6">
            <div className="col-span-1">
              <div className="aspect-[32/15] cursor-pointer" onClick={() => navigate(`/ragam-informasi/${activeTab}/${ragamInfo?.data?.[0].slug}`)}>
                <LazyLoadImage
                  alt="img"
                  className="h-full w-full rounded-xl object-cover bg-gray-50"
                  src={ragamInfo?.data?.[0]?.featured_image?.url}/>
              </div>
              <div className="my-2 font-bold text-2xl md:pr-10 cursor-pointer" onClick={() => navigate(`/ragam-informasi/${activeTab}/${ragamInfo?.data?.[0].slug}`)}>{ragamInfo?.data?.[0]?.title}</div>
              <div className="flex gap-2 items-center text-gray-800">
                <div className="font-normal text-sm mr-2">Oleh <span className="font-semibold text-sm uppercase">{ragamInfo?.data?.[0]?.author?.name}</span></div>
                <Clock size={20} color="#008DD1"/>
                <div className="text-semibold text-sm text-gray-800">{formatDate(ragamInfo?.data?.[0]?.created_at)}</div>
              </div>
              <div className="text-sm text-gray-800 my-3" dangerouslySetInnerHTML={{  __html:truncateHTML(ragamInfo?.data?.[0]?.content, 100, true)}}></div>
              <div onClick={() => navigate(`/ragam-informasi/${activeTab}/${ragamInfo?.data?.[0].slug}`)} className="text-[#008DD1] font-semibold my-3 flex items-center cursor-pointer">
                <span className='mr-2'>Baca Selengkapnya</span>
                <ArrowUpRight size={32} color="#008DD1"/>
              </div>
            </div>
            <div className="col-span-1">
              {ragamInfo?.data?.slice(0,5).map((item, key) => {
                return(
                  <div className="border-collapse border border-slate-100 rounded-lg flex shadow-lg items-center gap-3 mb-4 cursor-pointer hover:shadow-sm transition ease-in-out">
                    <div className="aspect-video">
                      <LazyLoadImage
                        alt={item.title}
                        className="w-[220px] h-full rounded-l-lg"
                        src={item?.featured_image?.url}/>
                    </div>
                    <div className="gap-y-3 md:pr-2">
                      <div className="my-1 font-bold" onClick={() => navigate(`/ragam-informasi/${activeTab}/${item?.slug}`)}>{truncateText(item?.title || '', 35)}</div>
                      <div className="md:flex hidden gap-2 items-center text-gray-700">
                        <div className="font-normal text-sm mr-2">Oleh <span className="font-semibold text-sm uppercase">{item?.author?.name}</span></div>
                        <Clock size={20} color="#008DD1"/>
                        <div className="text-semibold text-sm text-gray-800">{formatDate(item?.created_at)}</div>
                      </div>
                      <div className="text-sm text-gray-600 my-1 hidden md:block" dangerouslySetInnerHTML={{  __html:truncateHTML(ragamInfo?.data?.[0]?.content, 80, true)}}></div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        }
      </div>
      
      <Statistics/>

      <div className="mb-8 md:px-12 px-6">
        <div className="md:grid md:grid-cols-3 gap-10 md:gap-20 items-center">
          <div className="col-span-2 mb-10">
            <div className="py-1 w-fit text-xl text-gray-800 border-b-4 border-yellow-400 mb-5 font-bold">Galeri Instagram</div>
            <div className="py-1 w-fit text-sm text-gray-500 mb-5 font-semibold flex items-center">
              <img className='w-5 h-5' src={dataGeneral?.social_media?.filter((a) => a.social_media_name == 'Instagram')[0]?.image?.url} alt="ig" />
              <a href={dataGeneral?.social_media?.filter((a) => a.social_media_name == 'Instagram')[0]?.social_media_link} target='_blank'>
                <span className="ml-2">ditsmp.kemdikbud</span>
              </a>
            </div>
              <div className="grid md:grid-cols-3 grid-cols-2 gap-6">
                {dummyGallery.map((item,key) => {
                    return(
                        <a href={item.link} key={key} target="_blank">
                          <img src={item.images} alt="img" className='w-full md:h-[300px] h-[180px] object-contain rounded-lg bg-white' />
                        </a>
                    )
                })}
              </div>
          </div>
          <div className="col-span-1">
            <div className="py-1 w-fit text-xl text-gray-800 border-b-4 border-yellow-400 mb-5 font-bold">Kunjungi Sosial Media Kami</div>
            <div className="flex items-center gap-8">
              {!loadingGeneral && dataGeneral?.social_media?.map((item, key) => {
                return(
                  <a key={key} href={item?.social_media_link} target='_blank'>
                    <img className='w-10 h-10' src={item?.image?.url} alt={item?.social_media_name} />
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Home;